.single-playbill-content__play {
  width: 100%;
}

.archive-playbill {
  position: relative;
}

@media (min-width: $breakpoint-medium) {
  .single-playbill-content__play {
    width: 75%;
  }
}
