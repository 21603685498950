/*
* Checkout Page
*/
.checkout-review-order {
  border-top: $defaultBorderWidth solid $inputBorderColor;

  @media (min-width: $breakpoint-medium) {
    border-radius: $defaultRadius;
    border: $defaultBorderWidth solid $inputBorderColor;
  }
}

.breakdown-wrap {
  max-height: 0 !important;
  transition: all 0.2 ease 0s;
  opacity: 0;
  visibility: collapse;
  overflow: hidden;

  &.open {
    opacity: 1;
    max-height: none;
    visibility: visible;
    overflow: visible;
  }
}
