.tribe-events-widget-events-list__view-more {
  display: none;
}

.tickets-wrap {
  max-width: 144rem;
}

.single-events-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (min-width: $breakpoint-small) {
  .single-events-content {
    position: relative;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
  }
}

.seats-info {
  cursor: pointer;
}

.single-event {
  #tribe-tickets {
    .icon {
      fill: $white;
    }

    #tribe-tickets__notice__tickets-in-cart {
      display: none;
    }

    .tribe-tickets__item__quantity {
      text-align: right;
    }

    .tribe-tickets__item__quantity__number {
      display: inline-block;
    }

    .single-tickets-quantity {
      display: inline-block;
      width: 44px;
      height: 44px;
      font-size: 20px;
      line-height: 1.7;
      text-align: center;
      border: 0;
      outline: 0;
      color: inherit;
      background: transparent;
      appearance: textfield;
      -webkit-tap-highlight-color: transparent;
    }

    .tribe-tickets__item__quantity__add,
    .tribe-tickets__item__quantity__remove {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      font-size: 20px;
      border: 0;
      border-radius: 50%;
      outline: 0;
      appearance: none;
      background: transparent;
      touch-action: manipulation;
      -webkit-tap-highlight-color: transparent;
    }

    .tribe-tickets__item__quantity__add {
      background-color: $secondary;
    }

    .tribe-tickets__item--inactive {
      padding: 16px;
    }
  }
}

.faAGPG {
  border: 1px solid lighten($secondary, 17%);
}

.enVtEx {
  text-align: center;
  position: relative;
  margin: 0;
  padding-bottom: 4px;
  font-weight: 900;
  text-transform: uppercase;
}

.YXzQw {
  -moz-box-flex: 1;
  flex-grow: 1;
  margin: 0 8px 24px;
}

.cYIvcn {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: rgb(191, 191, 191);
}

.hZDmOj {
  display: flex;
}

.zGyTm {
  align-self: center;
  max-width: 90ch;
  margin-left: 16px;
  font-weight: 600;
}

.ggGxPL {
  max-width: 90ch;
  margin-top: 12px;
  padding-left: 40px;
}

.BaseSvg-yh8lnd-0 {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  transition: transform 0.2s ease-out 0s;
  fill: currentColor;
}
