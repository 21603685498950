.entry-share-btns {
  margin: 0;
  list-style: none;
}

.entry-share-btns li {
  display: inline-block;
  margin-right: 0.5rem;
}

.entry-share-btns a,
.entry-content .entry-share-btns a {
  font-size: 0.75em;
  width: 3.2rem;
  height: 3.2rem;
  display: block;
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 30px;
  background: $primary;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.entry-share-btns a:hover,
.entry-share-btns a:focus {
  text-decoration: none;
  color: #fff;
  background: $secondary;
}

.entry-share-btn-icon {
  position: relative;
  top: 7px;
  display: inline-block;
  fill: #fff;
  stroke: none;
}

.entry-share-btns svg {
  width: 1.4em;
  height: 1.4em;
  vertical-align: top;
}
