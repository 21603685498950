//------------------------------------*\
//  # Login
//------------------------------------*/

.login-account {
  &__canvas {
    width: 100%;
    height: 100%;
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .dataset {
      width: 100%;
      padding-top: 6rem;

      li {
        display: inline-block;
        width: 49%;
      }

      a {
        display: block;
        width: 100%;
        text-align: center;
      }

      .ui-tabs-active {
        a {
          color: $primary;
        }
      }
    }
  }
}

.woocommerce-message {
  text-align: center;
  padding-top: 1rem;
}

/* stylelint-disable no-descending-specificity */

.login #login_error,
.login .message,
.login .success {
  padding: 12px;
  margin-left: 0;
  margin-bottom: 20px;
  border-radius: $defaultRadius;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: $white !important;
  }
}

.login .success {
  background: $success;
}

.login #login_error {
  background: $warning;
}

.login form {
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  font-weight: 400;
}

#loginform p.submit,
.login-action-lostpassword p.submit {
  border: none;
  margin: -10px 0 20px;
}

.login .input::-ms-clear {
  display: none;
}

.login .pw-weak {
  margin-bottom: 15px;
}

.login .button.wp-hide-pw {
  position: absolute;
  background: 0 0;
  box-shadow: none;
  line-height: 2;
  width: 2.2rem;
  height: 2.2rem;
  min-width: 44px;
  min-height: 44px;
  border: 0;
  margin: 0;
  padding: 5px 9px;
  right: 0;
  top: 0;
  outline: 0;
  touch-action: manipulation;
}

.login .button.wp-hide-pw .dashicons {
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  top: 0.4rem;
}

.login .wp-pwd {
  position: relative;
}

.no-js .hide-if-no-js {
  display: none;
}

.login form.shake {
  animation: shake 0.2s cubic-bezier(0.19, 0.49, 0.38, 0.79) both;
  animation-iteration-count: 3;
  transform: translateX(0);
}

@keyframes shake {
  25% {
    transform: translateX(-20px);
  }

  75% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .login form.shake {
    animation: none;
    transform: none;
  }
}

.login-action-confirm_admin_email #login {
  width: 60vw;
  max-width: 650px;
  margin-top: -2vh;
}

@media screen and (max-width: 782px) {
  .login-action-confirm_admin_email #login {
    box-sizing: border-box;
    margin-top: 0;
    padding-left: 4vw;
    padding-right: 4vw;
    width: 100vw;
  }
}

.login form .forgetmenot {
  font-weight: 400;
  cursor: pointer;
}

.login .reset-pass-submit {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.login .reset-pass-submit .button {
  display: inline-block;
  float: none;
  margin-bottom: 6px;
}

.login .admin-email-confirm-form .submit {
  text-align: center;
}

.admin-email__later {
  text-align: left;
}

.login form p.admin-email__details {
  margin: 1.1em 0;
}

.login h1.admin-email__heading {
  border-bottom: 1px #f0f0f1 solid;
  color: #50575e;
  font-weight: 400;
  padding-bottom: 0.5em;
  text-align: left;
}

.admin-email__actions div {
  padding-top: 1.5em;
}

.login .admin-email__actions .button-primary {
  float: none;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

#login form p.submit {
  margin: 0;
  padding: 0;
}

.login label {
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
}

#rememberme {
  cursor: pointer;
}

.login .forgetmenot label,
.login .pw-weak label {
  line-height: 1.5;
  vertical-align: baseline;
}

.login h1 {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 44px;
  text-align: center;
}

// .login h1 a {
//   width: 73px;
//   height: 73px;
//   margin-left: auto;
//   margin-right: auto;
//   background-image: url(../../wp-content/uploads/site/news-hero.jpg);
//   background-image: none, url(../../wp-content/uploads/site/news-hero.jpg);
//   background-size: 73px;
//   background-position: center top;
//   background-repeat: no-repeat;
//   color: $white;
//   font-weight: 400;
//   line-height: 1.3;
//   padding: 0;
//   border-radius: $defaultRadius;
//   text-decoration: none;
//   text-indent: -9999px;
//   outline: 0;
//   overflow: hidden;
//   display: block;
// }

p.message.register {
  display: none;
}

.input-group {
  display: flex;
  position: relative;

  @media (hover: hover) {
    &:hover {
      .input-group-text {
        border-color: $inputBorderColorHover;
      }
    }
  }

  &:focus {
    .input-group-text {
      border: $inputBorderColorHover;
    }
  }
}

.input-group-prepend {
  display: flex;
}

.input-group-text {
  display: flex;
  left: 0;
  padding: 6px 12px;
  border: 2px solid $inputBorderColor;
  border-right: 0;
  border-radius: $defaultRadius 0 0 $defaultRadius;
  align-items: center;
  background-color: $inputBorderColor;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: $inputBorderColor !important;
  }
}

.password-input {
  position: relative;
  display: block;
}

.show-password-input {
  position: absolute;
  line-height: 2;
  min-width: 44px;
  min-height: 44px;
  border: 0;
  margin: 0;
  padding: 0;
  right: 12px;
  top: 8px;
  color: $primary;
  font-weight: 600;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  touch-action: manipulation;
  // background: svg-load("../svg/eye-outline.svg", height=24px, fill=#fff, stroke=#fff);
  // background-repeat: no-repeat;
  // background-position: center;
}

// .display-password {
//   background: svg-load("../svg/eye-off-outline.svg", height=24px, fill=#fff, stroke=#fff);
//   background-repeat: no-repeat;
//   background-position: center;
// }

#reg_username {
  border-left: 0;
  border-radius: 0 $defaultRadius $defaultRadius 0;
}

.forgetmenot {
  display: flex;
  align-items: center;
}

.login #nav {
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 0 0 0;
}

#backtoblog {
  display: none;
}

.login .privacy-policy-page-link {
  display: none;
  text-align: center;
  width: 100%;
  margin: 5em 0 2em;
}

.js.login-action-rp input[type="password"],
.js.login-action-rp input[type="text"] {
  margin-bottom: 0;
}

.login #pass-strength-result {
  font-weight: 600;
  margin: -1px 5px 16px 0;
  padding: 6px 5px;
  text-align: center;
  width: 100%;
}

body.interim-login {
  height: auto;
}

.interim-login #login {
  padding: 0;
  margin: 5px auto 20px;
}

.interim-login.login h1 a {
  width: auto;
}

.interim-login #login_error,
.interim-login.login .message {
  margin: 0 0 16px;
}

.interim-login.login form {
  margin: 0;
}

.screen-reader-text,
.screen-reader-text span {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

input::-ms-reveal {
  display: none;
}

/* stylelint-enable no-descending-specificity */
