.uk-input-error {
  border: 1px solid $global-danger-background !important;
}

/* stylelint-disable no-descending-specificity */

/** Search form */
// TODO: .search-form {}
// TODO: .search-form label {}
// TODO: .search-form .search-field {}
// TODO: .search-form .search-submit {}

::placeholder {
  color: $inputColor !important;
}

// input {
//   &[type="checkbox"] {
//     width: 1.6rem;
//     height: 1.6rem;
//     margin: $checkboxMarginTop $checkboxMarginRight 0 0;
//     border-radius: 2px;
//     background-color: $white;
//     color-adjust: exact;
//     appearance: none;
//     cursor: pointer;

//     &::before {
//       content: "";
//       position: absolute;
//       width: 1.2em;
//       height: 1.2em;
//       clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
//       transform: scale(0);
//       transform-origin: bottom left;
//       background-color: $white;
//     }

//     &:focus {
//       box-shadow: 0 0 0 0.4rem rgba(42, 159, 214, 0.25);
//     }

//     &:active {
//       -webkit-filter: brightness(90%);
//       filter: brightness(90%);
//     }

//     &:checked {
//       background-color: $primary;

//       &::before {
//         transform: scale(1);
//       }
//     }

//     &[disabled] {
//       background: lighten($primary, 3%);
//       cursor: not-allowed;
//     }
//   }
// }

.input,
.select,
.textarea {
  @if ($inputRounded) {
    @include rounded($inputRounded);
  }

  @if ($inputBorderColor) {
    border: $inputBorderWidth solid $inputBorderColor;
  }

  @if ($inputMinHeight) {
    min-height: $inputMinHeight;
  }

  @if ($inputMinWidth) {
    min-width: $inputMinWidth;
  }

  background-clip: border-box;
  background-color: rgba($color: #ffff, $alpha: 0.05); //$inputBackground;
  color: $inputColor;
  margin: 0 0 0 0;
  outline: 0;
  padding: $inputPaddingVertical $inputPaddingHorizontal;
  width: 100%;
  will-change: transform;
  filter: none;
  transition: all 0.2s ease-in-out;

  @media (hover: hover) {
    &:hover {
      border-color: $inputBorderColorHover;
    }
  }

  &:focus {
    border-color: $inputBorderColorFocus;

    &:required {
      &:invalid {
        background-color: $inputBackgroundInvalid;
        border-color: $inputBorderColorInvalid;
        color: $inputColorInvalid;
      }
    }
  }

  &:invalid {
    background-color: $inputBackgroundInvalid;
    color: $inputColorInvalid;

    @if ($inputBorderWidthInvalid) {
      border: $inputBorderWidthInvalid solid $inputBorderColorInvalid;
    }

    &:hover {
      border-color: $inputBorderColorInvalidHover;
    }

    &:focus {
      border-color: $inputBorderColorInvalidFocus;
    }
  }

  &:required {
    background-color: $inputBackgroundRequired;
    color: $inputColorRequired;

    @if ($inputBorderWidthRequired) {
      border: $inputBorderWidthRequired solid $inputBorderColorRequired;
    }

    &:hover {
      border-color: $inputBorderColorRequiredHover;
    }

    &:focus {
      border-color: $inputBorderColorRequiredFocus;
    }
  }

  &:-webkit-autofill {
    border: $inputBorderWidth solid $inputBorderColorFocus;
    -webkit-text-fill-color: $white !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  }
}

.input {
  @include placeholder {
    color: $inputPlaceholderColor;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &[disabled],
  &.#{$inputDisabledModifier} {
    @if ($inputBorderWidthDisabled == false) {
      border: none;
    }

    @else {
      border-width: $inputBorderWidthDisabled;
    }

    background-color: $inputBackgroundDisabled;
    color: $inputColorDisabled;
    cursor: $inputDisabledCursor;
  }

  &.-search {
    -webkit-appearance: textfield;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      display: none;
    }
  }

  &.-checkbox,
  &.-radio {
    margin-bottom: $checkboxMarginTop;
    margin-right: $checkboxMarginRight;
    padding: 0;
  }

  &.-reset,
  &.-submit {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

.input-container {
  margin-bottom: $inputMarginBottom;
}

.select {
  cursor: pointer;
  padding-right: $inputPaddingVertical;

  &[multiple] {
    min-height: $multiSelectMinHeight;
    vertical-align: top;

    &::after {
      content: " ";
    }
  }
}

/* stylelint-enable no-descending-specificity */
