/* stylelint-disable no-descending-specificity */

//------------------------------------*\
//  # Body Playbill
//------------------------------------*/

.playbill__data {
  margin: 0;
  padding: 16px;
}

.playbill__link {
  display: block;
  height: 100%;
  border-radius: $defaultRadius;
}

.tribe-events-c-messages__message {
  display: inline-flex;
  position: relative;
}

.tribe-common-c-svgicon--messages-not-found {
  width: 22px;
  margin-right: 16px;
  stroke: $white;
  background: $transparent;
}

.single-playbill-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: $breakpoint-medium) {
  .single-playbill-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: $breakpoint-large) {
  .single-playbill-content {
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.playbill__column {
  &.week {
    .tribe-events-header,
    .tribe-events-pro-week-mobile-events__event-featured-image-wrapper,
    .tribe-events-widget-events-week__view-more {
      display: none;
    }

    .widget_tribe-widget-events-week {
      * {
        color: $white;
      }
    }
  }
}

.playbill-section {
  padding-bottom: 3rem;
}

.slider-header {
  display: inline-block;
  width: 100%;

  .slider-nav,
  h3 {
    display: inline-block;
  }

  .slider-nav {
    float: right;
  }
}

.playbill__slider {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    background-color: rgb(2, 108, 223);
    background-image: linear-gradient(90deg, rgb(70, 117, 232), rgb(2, 108, 223));
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.1, 0.85, 0.25, 1) 0s;
    content: "";
  }

  &::after {
    background-color: rgb(2, 108, 223);
    background-image: linear-gradient(rgb(70, 117, 232), rgb(2, 108, 223));
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 32px;
    height: 100%;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.1, 0.85, 0.25, 1) 0s;
    content: "";
  }

  p {
    height: 2.8rem;
    overflow: hidden;
  }
}

.single-playbill {
  .tribe-compatibility-container {
    *,
    a,
    a:visited,
    a:focus,
    a:active,
    span,
    h3,
    time {
      color: $white;
    }
  }
}

.playbill__single-genre {
  padding-bottom: 1rem;
  text-transform: uppercase;
}

/* stylelint-enable no-descending-specificity */
