.header {
  position: relative;
  display: block;
  width: 100%;
  background-color: $primary;
  transition: top 0.2s ease-in-out;
  z-index: 3;

  .icon {
    font-size: 2.4rem;
    display: inline-block;
    vertical-align: middle;

    &.account {
      height: 28px;
    }
  }

  /* .header-content */
  &-content {
    height: 60px;
  }

  /* .header-logo */
  &-logo {
    position: relative;
    overflow: hidden;
    width: 144px;
    height: 100%;
    z-index: 3;

    .logo-responsive {
      position: absolute;
      top: 50%;
      left: 0;
      transition: all ease 0.3s;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      fill: white;
    }

    .logo-site {
      width: 0;
      margin: 0.7px 0 0 -0.4px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transition: all ease 0.3s;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      fill: white;
    }
  }
}

.header-cart {
  position: relative;

  svg {
    height: 30px;
  }

  .uk-badge {
    position: absolute;
    left: 47%;
    bottom: 23px;
  }
}

#menubar {
  > li > a {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 51%;
      left: 51%;
      bottom: 0;
      height: 1px;
      transition: left 0.3s ease, right 0.3s ease;
      background: #fff;
    }

    &:active,
    &:hover,
    &:focus {
      &::after {
        right: 0;
        left: 0;
      }
    }
  }
}
