$border-rounded-border-radius:                   2px !default;

.uk-cursor-pointer {
  cursor: pointer;
}

.uk-overflow-auto-y {
  overflow-y: auto;
}

.uk-border {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.uk-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.uk-border-remove-top {
  border-top: 0 !important;
}

.uk-border-remove-bottom {
  border-bottom: 0 !important;
}

.uk-border-remove-vertical {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.uk-border-remove-horizontal {
  border-left: 0 !important;
  border-right: 0 !important;
}
