$inverse-search-default-background: rgba(255, 255, 255, 0.2);
$inverse-search-default-focus-background: $inverse-search-default-background;

@mixin hook-inverse-search-default-input() {
  border: none;
}

// @mixin hook-inverse-search-default-input-focus() {
//   ::placeholder {
//     color: #000;
//   }
//   color: #000;
// }

// @mixin hook-inverse-search-toggle-hover() {
//   color: #000;
// }
