/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
  .uk-inline\@s {
    display: inline !important;
  }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
  .uk-inline\@m {
    display: inline !important;
  }
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
  .uk-inline\@l {
    display: inline !important;
  }
}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
  .uk-inline\@xl {
    display: inline !important;
  }
}
