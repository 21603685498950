.checkout {
  &-steps {
    &.dataset {
      position: relative;
      display: flex;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      //   width: 100%;
      //   height: 1px;
      //   background: #c8cbcc;
      //   box-shadow: -60px 0 0 #c8cbcc, 60px 0 0 #c8cbcc;
      // }
    }

    li {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      padding: 1.5rem 0;
    }

    &-header {
      color: #fff;
    }

    &.number {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding-left: 2px;
      height: 24px;
      width: 24px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      border-radius: 50%;
      background-color: $secondary;
    }

    &.title {
      display: flex;
      margin-left: 0.7rem;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: inherit;
      text-transform: uppercase;
    }

    &.active {
      border-bottom: 1px solid #000;

      * {
        color: $primary;
      }
    }
  }
}

@media (min-width: $breakpoint-xlarge) {
  .checkout-steps {
    &.content {
      font-size: 1rem;
    }
  }
}
