.single-tribe_events {
  .head-title {
    margin: 0;
    line-height: 1;
  }

  .attachment-event {
    display: none;

    @media (min-width: $breakpoint-small) {
      display: block;
    }
  }

  .events-schedule {
    h2 {
      margin: 0;
      font-weight: 500;
    }
  }

  .tickets__hall {
    margin: 8px 0 0 0;
  }
}
