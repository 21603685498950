@mixin hook-button() {
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

@mixin hook-inverse-button-primary() {
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

@mixin hook-inverse-button-primary-hover() {
  &:disabled {
    background-color: inherit;
  }
}

//------------------------------------*\
//  # Rounded Corners
//------------------------------------*/

// Border radius
//
// @param $keyword - top, right, bottom, left
// @param $radius - value defines how rounded el's corners are
//
@mixin rounded($keyword: "", $value: $defaultRadius) {
  $corners: false;

  @if ($keyword != false) {
    background-clip: border-box;

    @if ($keyword == left or $keyword == right) {
      $corners: border-top-#{$keyword}-radius, border-bottom-#{$keyword}-radius;
    }

    @else if ($keyword == top or $keyword == bottom) {
      $corners: border-#{$keyword}-left-radius, border-#{$keyword}-right-radius;
    }

    @if ($corners != false) {
      @each $corner in $corners {
        #{$corner}: $value;
      }
    }

    @else {
      @if (type-of($keyword) == number) {
        $value: $keyword;
      }

      @if (unit($value) == "") {
        $value: $value + rem;
      }

      border-radius: $value;
    }
  }
}

//------------------------------------*\
//  # Placeholder
//------------------------------------*/

// Styles form placeholder text
//
@mixin placeholder($color: $inputPlaceholderColor) {
  @include optional-at-root("::-webkit-input-placeholder") {
    color: $color;
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    color: $color;
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    color: $color;
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    color: $color;
    @content;
  }
}

//------------------------------------*\
//  # Utility
//------------------------------------*/

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

//------------------------------------*\
//  # Media Querys
//------------------------------------*/
@mixin tablet {
  @media only screen and (min-width: 76.8rem) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 99.2rem) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 120rem) {
    @content;
  }
}

/*
* Gradients
*/

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == "vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation == "horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

/*
* Animations
*/

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
