@import "common/variables";

/** Import everything from autoload */
;

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";
@import "~select2/src/scss/core";
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

/** Import theme styles */
@import "common/mixins";
// @import "common/global";
// @import "common/helpers";
// @import "theme/components";
@import "theme/flex";
@import "theme/section";
@import "theme/search";
// @import "theme/comments";
@import "theme/form";

// Elements
@import "theme/button";
@import "theme/text";
// @import "theme/tables";
@import "theme/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "theme/label";
@import "theme/overlay";
@import "layouts/login";
@import "layouts/search";
@import "layouts/blog";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/playbill";
@import "layouts/tickets";
@import "layouts/single-events";
@import "common/prints";
@import "layouts/share-buttons";
@import "layouts/shop";
@import "layouts/cart";
@import "theme/store/hero";
@import "layouts/archive-tickets";
@import "layouts/venues";
@import "layouts/archive_playbill";
@import "theme/utility";
@import "uikit/src/scss/uikit-theme.scss";

.user-avatar {
  svg {
    height: 16px;
  }
}

.blurred {
  filter: blur(15px);
}

.banner {
  margin: 0 auto;
}

.event-info-modal__additional-info-img {
  width: 65px;
  height: 65px;
  object-fit: cover;
}

.woocommerce-message {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666;
}

button#js-info {
  padding: 4px;

  @media (min-width: $breakpoint-small) {
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 5px;
  }
}

.cart-quantity {
  width: 44px;
  height: 44px;
  touch-action: manipulation;
}

input.minus {
  background-color: hsla(0, 0%, 100%, 0.15);
}

input.qty {
  color: inherit;
  border: none;
  outline: 0;
  background: transparent;
  appearance: textfield;
}

@media (min-width: $breakpoint-medium) {
  #main-sidebar {
    width: 333px;
  }
}

.lgOTor {
  position: absolute;
  top: 50%;
  right: 8px;
  fill: currentColor;
  transform: translateY(-50%) rotate(-90deg);
}

.jkPJaC {
  position: absolute;
  display: block;
  inset: 0;
}

//------------------------------------*\
//  # Preloader
//------------------------------------*/

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  bottom: 0;
  text-align: center;
  background-color: $bodyBackground;
  transition: all 400ms;
  z-index: 99999999999;
  display: none;

  .loading {
    margin-bottom: 1.6rem;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .progress-bar {
    height: 0.2rem;
    width: 10rem;
    background: #b3b3b3;
  }

  .progress-bar::after {
    content: "";
    width: 3rem;
    height: 0.2rem;
    background: $primary;
    display: block;
    border-radius: 0.5rem;
    animation: animation 1.5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  }

  @keyframes animation {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(7rem);
    }

    100% {
      transform: translateX(0);
    }
  }
}

.control {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  border: 1px solid $white;
  cursor: pointer;

  svg {
    height: auto;
  }

  path {
    fill: $white;
  }
}

:root {
  --background: hsl(240, 80%, 10%);
}

.bg-is-dark {
  // background: $bodyBackground;
  // background-image: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 44%);
  //background: radial-gradient(circle at -20% -20%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 50%);
  // background-attachment: fixed;
}

body {
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;

  &.uk-background-dark {
    // background: radial-gradient(60vmax 60vmax at 0% 0%, hsla(240, 100%, 2%, 0.9) 0%, hsla(240, 100%, 2%, 0) 95%),
    //   radial-gradient(80vmax 50vmax at 110% -10%, hsla(175, 100%, 60%, 0.9) 0%, hsla(200, 100%, 50%, 0.5) 50%, hsla(240, 100%, 50%, 0) 95%),
    //   radial-gradient(90vmax 50vmax at 50vmax 50vmax, hsla(260, 100%, 60%, 0.9) 0%, hsla(240, 100%, 60%, 0) 95%) var(--background);
    background:
      radial-gradient(60vmax 60vmax at 0% 0%, hsla(240, 100%, 2%, 0.9) 0%, hsla(240, 100%, 2%, 0) 95%),
      radial-gradient(80vmax 50vmax at 0% 0%, hsla(216, 100%, 31%, 0.9) 0%, hsla(240, 100%, 50%, 0) 95%),
      radial-gradient(90vmax 50vmax at 50vmax 50vmax, hsla(216, 100%, 31%, 0.9) 0%, hsla(240, 100%, 60%, 0) 95%) var(--background);
  }

  &:not(.uk-background-dark) {
    .footer {
      background-color: $bodyBackground;
    }
  }
}

.uk-logo {
  flex: 0 1 auto;
}

.tribe-events .tribe-events-view-loader {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: hsla(0, 0%, 100%, 0.6);
}

.recurringinfo {
  display: none;
}

.mp-terms-and-conditions {
  color: $white;
  padding: 0;
  margin: 0;
}

.tribe-common-a11y-hidden {
  display: none !important;
  visibility: hidden;
}

.tribe-common-a11y-visual-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tribe-common-a11y-visual-show {
  clip: auto;
  height: auto;
  margin: 0;
  position: static;
  width: auto;
}

.tribe-common-c-svgicon__svg-stroke {
  stroke: currentColor;
}

.head-title {
  color: #fff;

  &__blue {
    padding-left: 1rem;
    border-left: $inputBorderWidth solid $primary;
  }
}

.tribe-events-nav-pagination {
  display: none;
}

.svg-wrap__icons-round {
  display: block;
  text-align: center;
  height: 100%;
}

@media screen and (min-width: $breakpoint-xlarge) {
  .svg-wrap__icons-round {
    p {
      padding-left: 1.6rem;
    }
  }
}

//------------------------------------*\
//  # Cookies Pop up
//------------------------------------*/

.popup {
  color: $white;

  &-cookies {
    position: fixed;
    bottom: 6.4rem;
    left: auto;
    right: 1.6rem;
    margin-left: 1.6rem;
    animation: 0.6s cubic-bezier(0.1, 0.85, 0.25, 1) 0s 1 normal forwards running cookieIn;

    .icon {
      fill: $white;
      stroke: $white;
      cursor: pointer;

      &.cookies {
        margin-right: 16px;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    max-width: 452px;
    min-height: 94px;
    padding: 1.6rem;
    border-radius: $defaultRadius;
    // background-color: #1f262d;
    background-color: rgba(0, 0, 0, 0.85);
    //background-image: linear-gradient(104deg, $secondary 5%, $primary);
    box-shadow: rgba(0, 0, 0, 0.24) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 0 2px 0;
    pointer-events: auto;
  }
}

.cookies-message {
  .title {
    font-weight: 700;
    line-height: 18px;
  }

  .body {
    margin: 6px 0 0;
  }
}

.svg-icon {
  height: 2.4rem;
}

.tribe-events-calendar-list__event-venue {
  span {
    display: block;
  }
}

.tribe-events-c-small-cta__price {
  display: none;
}

.tribe-events-c-small-cta__stock {
  display: none;
}

//------------------------------------*\
//  # Select 2
//------------------------------------*/

.select2 {
  &-container--default,
  &-selection--single {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  &-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        height: 100%;
        color: $white;
        line-height: calc(40px);
      }

      .select2-selection__arrow {
        height: 100%;
      }
    }
  }

  &-container {
    .select2-selection--single {
      height: 100%;
    }
  }

  &-search {
    &--dropdown {
      padding: 0 !important;
    }

    &__field {
      margin-bottom: 1rem;
      height: calc(40px);
      border: 0 !important;
      border-bottom: $inputBorderWidth solid $inputBorderColor !important;
      outline: 0;
    }
  }

  &-dropdown {
    padding: 0.8rem !important;
    border: 0 !important;
    background-color: $bodyBackground;
  }

  &-results__option {
    color: $white;
  }
}

.tribe-events-single-section.tribe-events-event-meta.primary.tribe-clearfix {
  width: 100%;
}

.checkout-order-url {
  padding-top: 4.8rem;
  padding-bottom: 2.4rem;
}

@keyframes caZuFg {
  from {
    transform: scale(0.4);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes cookieIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modalIn {
  from {
    transform: translateY(-120vh);
  }

  to {
    transform: translateY(-120vh);
  }
}

.link-payment {
  width: 100%;
  color: $white;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

.woocommerce-checkout .StripeElement {
  width: 100%;
}

/* Front Page */

.front-newsletter {
  height: 13.75rem;
  padding: 0 1rem;

  @include background-gradient($secondary, $primary, horizontal);

  .banner .title {
    color: #fff;
    font-weight: 800;
  }

  &__content {
    width: 100%;
    max-width: $breakpoint-xlarge;
  }
}

.lfXZkS {
  position: relative;
}

.CZQXV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.SMWVX {
  position: absolute;
  z-index: 0;
  background: $bodyBackground;
  background-image: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 50%);
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  min-height: 600px;
  background-position: center center;
  background-size: cover;
  transform: translate(-50px, -50px);
  filter: blur(15px);
}

.dCZQX {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.bSMWV {
  position: absolute;
  z-index: 0;
  // background-color: rgb(2, 108, 223);
  // background: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 88%);
  // width: calc(100% + 100px);
  // height: calc(100% + 100px);
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  transform: translate(-50px, -50px);
  filter: blur(15px);

  // @media (min-width: $breakpoint-small) {
  //   background: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 77%);
  // }

  // @media (min-width: $breakpoint-medium) {
  //   background: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 66%);
  // }

  // @media (min-width: $breakpoint-large) {
  //   background: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 44%);
  // }

  // @media (min-width: $breakpoint-xlarge) {
  //   background: radial-gradient(circle at 0% 0%, #015d8c, #025989, #034e82, #064076, #083269 15%, #0a255b, #0c1a4d, #0c1340, #0c0d36, #0d0c2d, #0e0b28, #0e0b26 33%);
  // }

  &.is-sticky {
    position: sticky;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.sc-b4uvde-2 {
  position: relative;
}

.biujFn {
  display: block;
  top: 50%;
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0 16px 16px 0, rgba(0, 0, 0, 0.12) 0 0 16px 0;
  border-radius: 8px;
  height: auto;
  max-height: calc(-176px + 100vh);
  overflow: hidden;
  transition: opacity 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s, transform 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s;
}

.cWceQo {
  position: relative;
  line-height: 1.25;
  font-weight: 600;
  text-align: left;
  box-shadow: none;
  padding: 16px;
}

.iFmuIW {
  display: flex;
  -moz-box-align: center;
  align-items: center;
}

.jLyRYo {
  font-family: inherit;
  font-weight: 600;
  line-height: 2.43;
  width: 100%;
  padding: 0 12px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(2, 108, 223);
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

.hBIdyw {
  width: auto;
}

.gRNkfj {
  position: relative;
  overflow: hidden;
  box-shadow: none;
  padding: 16px;
}

@media (min-width: $breakpoint-large) {
  .gRNkfj {
    padding: 24px 32px 32px;
  }
}

.mp-form-control,
.mp-wallet-button,
.mp-alert-texts-checkout-test-mode {
  color: $black;
}

.fHheuH {
  color: rgb(224, 224, 224);
  height: 22px;
  min-width: 10px;
  fill: $white;
}

#account-not {
  width: 26px;
  height: 26px;
  border: 1px solid white;
  background: transparent;
}

.js-modal-trigger {
  cursor: pointer;
}

.ribbon-point {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;

  &-gray {
    background-color: #333;
  }

  &-primary {
    background-color: $global-primary-background;
  }

  &-success {
    background-color: $global-success-background;
  }

  &-warning {
    background-color: $global-warning-background;
  }

  &-danger {
    background-color: $global-danger-background;
  }
}

.lftQDn {
  &.is-hidden {
    display: none;
  }
}

.single-tribe_venue {
  .spacing-title {
    padding: 0;
  }

  .content.wrapped {
    padding: 0;
  }
}

.uk-background-dark {
  background-color: $bodyBackground;
}

.uk-background-darken {
  background-color: lighten($bodyBackground, 11%);
}

.uk-background-light {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.uk-padding-xsmall {
  padding: 8px;
}

.fhhefY {
  display: flex;
  flex-flow: row nowrap;
  -moz-box-pack: end;
  justify-content: flex-end;
  height: auto;
  padding: 24px 24px 4px;
}

.hVbfmI {
  height: 100%;
  overflow-y: auto;
}

.YoDOb {
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid rgb(224, 224, 224);
  padding: 16px 24px;
}

.YoDOb.event-list-panel-item--lineupImage {
  padding-right: 0;
}

.kqrCfE.event__list__link--modal {
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  margin-top: 16px;
  margin-left: 4px;
  margin-right: 4px;
  text-align: center;
  width: 90px;
}

.izUOEw {
  color: rgba(38, 38, 38, 0.65);
  margin: 0;
  max-height: 20px;
  overflow: hidden;
  position: relative;
}

.kIYIWS {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 50%;
  border: 1px solid rgb(224, 224, 224);

  img {
    object-fit: cover;
    height: 100%;
  }
}

.crbKxn {
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: rgb(38, 38, 38);
}

.kqrCfE {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
}

.hTRmfd {
  display: flex;
  flex-flow: column nowrap;
  -moz-box-pack: center;
  justify-content: center;
}

.cPITfn {
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  color: rgb(2, 108, 223) !important;
  text-decoration: none;
  transition: color 0.3s ease 0s;
}

.jEGBcZ {
  color: rgba(38, 38, 38, 0.65);
  line-height: normal;
  margin: 0;
}

.hNnNUE {
  display: flex;
  flex-flow: row nowrap;
  padding: 16px;
}

.duZEkn {
  font-family: inherit;
  font-weight: 600;
  line-height: 2.43;
  width: 100%;
  padding: 0 12px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(2, 108, 223);
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  display: block;
  text-decoration: none;
}

.text-dark {
  color: #666 !important;
}

.kVDZYG {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 8px;
}

.klxzPL {
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  background-color: transparent;
  cursor: pointer;
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  width: 28px;
  border: medium none;
}

.iRxubR {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gen8 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hvjSQK {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}

#google-login {
  padding-top: 16px;
}

.mp-panel-custom-checkout {
  background-color: transparent !important;

  p,
  label {
    color: white !important;
  }
}
