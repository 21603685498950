/* stylelint-disable no-descending-specificity */

//------------------------------------*\
//  # Archive Tickets
//------------------------------------*/

.archive-tickets {
  .hero__content {
    svg {
      fill: transparent;
      stroke: $white;
      stroke-width: 1;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 1;
      fill-opacity: 0;
      transition: fill-opacity 1s;

      &.finished {
        fill: $white;
        fill-opacity: 1;
      }
    }
  }

  .small--container {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    padding: 0 $bumperPadding;
  }
}

.content-header {
  position: relative;
  overflow: hidden;

  &-block {
    position: relative;
    padding: 1.6rem;
  }

  &-bg {
    position: absolute;
    inset: 0;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(-50px, -50px);
    filter: blur(15px);
    z-index: -0;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1.6rem;
  }

  @media (min-width: $breakpoint-small) {
    &-info {
      flex-direction: row;
    }
  }

  /* .content-header-details
 */
  &-details {
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
    }

    // &.banner {
    //   padding: 2rem;
    //   background: rgb(156, 17, 74);
    //   background: linear-gradient(90deg, rgba(156, 17, 74, 1) 0%, rgba(225, 14, 83, 1) 35%, rgba(160, 16, 75, 1) 100%);
    // }
  }
}

.indexstyles__Title {
  color: $primary !important;
  line-height: 1.5;
  text-transform: uppercase;
}

.indexstyles__Details {
  line-height: 1.5;
  text-transform: uppercase;
}

.events-schedule {
  line-height: 1.5;
}

.dvTpXv {
  display: none;
}

.kBfIRR {
  display: inline-block;
  line-height: 1.5;
}

.tickets__actions {
  display: none;
  margin-top: 6px;

  .button {
    padding: 4px 8px;
  }
}

.single-tickets {
  .head-title {
    padding: 0;
    font-weight: 900;
    line-height: 1.5;
  }

  .content-header-details {
    text-align: center;
    padding-top: 1.6rem;
  }

  @media (min-width: $breakpoint-medium) {
    .content-header-details {
      text-align: left;
    }
  }
}

.attachment {
  border-radius: $defaultRadius;

  /* .attachment-featured
 */
  &-featured {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-small {
    max-width: 13rem;
  }

  &-event {
    max-width: 20rem;
  }

  @media (min-width: $breakpoint-small) {
    &-event {
      margin-right: 2.4rem;
    }
  }

  &.scale-in {
    animation: 0.6s cubic-bezier(0.1, 0.85, 0.25, 1) 0s 1 normal forwards running caZuFg;
    will-change: transform;
  }

  &.has-shadow {
    box-shadow: rgba(0, 0, 0, 0.6) 0 5px 25px 0;
  }
}

.tribe-events-event-image {
  margin: 0;

  img {
    max-width: 200px;
    border-radius: $defaultRadius;
    box-shadow: rgba(0, 0, 0, 0.6) 0 5px 25px;
  }
}

//------------------------------------*\
//  # Single Event
//------------------------------------*/

.tribe-common .tribe-common-c-loader {
  display: flex;
  padding-top: 192px;
}

.tribe-common--breakpoint-medium.tribe-common .tribe-common-c-loader {
  padding-top: 288px;
}

.tribe-common .tribe-common-c-loader__dot circle {
  animation-name: a;
  animation-duration: 2.24s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  fill: currentColor;
  opacity: 0.07;
}

.tribe-common .tribe-common-c-loader__dot--first circle {
  animation-delay: 0.45s;
}

.tribe-common .tribe-common-c-loader__dot--second circle {
  animation-delay: 1.05s;
}

.tribe-common .tribe-common-c-loader__dot--third circle {
  animation-delay: 1.35s;
}

@keyframes a {
  50% {
    opacity: 1;
  }
}

.tribe-common-c-loader.tribe-tickets-loader__tickets-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 0;
  top: 0;
  background: rgba(76, 68, 68, 0.6);
  z-index: 100;
}

.about__tickets-events {
  font-weight: 700;
  line-height: 60px;
}

.tribe-events-event-meta {
  padding: 16px $bumperPadding;
}

.tickets__event_single-notices {
  position: relative;
  padding: 4px 16px;
  color: $white;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.5);
}

.playbill__slider-data {
  width: 100%;
  height: 100%;
  font-weight: 700;
  line-height: 1.4;
  position: absolute;
  top: 0;
  z-index: 0;
}

.archive-tickets-content-events {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(10, auto);
  grid-gap: 16px;
}

.archive-tickets-single-events-bg {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-size: 100%;
  overflow: hidden;
}

.archive-tickets-single-events-title {
  position: relative;
  padding: 16px;
}

.archive-tickets-content {
  padding: 16px;
}

@media screen and (min-width: $breakpoint-medium) {
  .archive-tickets-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: $breakpoint-large) {
  .archive-tickets-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.ticket-banner {
  &.contents {
    position: relative;
    border-radius: $defaultRadius;
    width: 100%;
    height: 120px;
    padding: 1rem;

    h2 {
      color: $white;
      position: absolute;
      top: 60px;
      transform: translate(0, -50%);
    }
  }
}

.single-tickets-info {
  h3 {
    margin: 0;
    padding: 12px 16px;
    font-weight: 700;
  }

  summary {
    padding: 12px 16px;
    font-weight: 500;
  }

  .faqs-grid__details {
    padding: 0 0 0 32px;
    font-weight: 300;
    line-height: 1.3;
  }

  &.dates * {
    color: $white !important;
  }

  &.info {
    span {
      display: block;
      line-height: 1.3;
    }
  }
}

.tribe-tickets__footer__quantity,
.tribe-tickets__footer__total {
  display: none;
  line-height: 1.5;
}

.tribe-tickets__footer--active {
  .tribe-tickets__footer__quantity,
  .tribe-tickets__footer__total {
    display: block;
  }
}

.tickets-tickets__title {
  min-height: 50px;
  font-weight: 700 !important;
  text-transform: uppercase;
  line-height: 50px;
  text-align: center;
  letter-spacing: 1px;
  border-bottom: 1px solid rgb(191, 191, 191) !important;
}

// Single Tickets Page

// The Content
.single-tickets-content {
  display: flex;
  flex-direction: column;
}

// The widget

.single-widget-event-events-list__event-datetime {
  margin: 0;
  line-height: 1.5;
}

.single-widget-event-events-list__event-title {
  margin: 0;
  line-height: 1.5;

  a {
    color: $white !important;
  }
}

.single-widget-event-events-list-venue-name {
  margin: 0;
  line-height: 1.5;
}

.single-events-calendar-list__event-title {
  line-height: 1.5;
}

.single-events-calendar-list__event-datetime {
  margin: 0;
  line-height: 1.5;
}

.single-events-calendar-list__month-separator {
  display: flex;
  align-items: center;
  height: 60px;

  &::after {
    background-color: #d5d5d5;
    content: "";
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    height: 1px;
    margin-left: 20px;
  }
}

.single-events-calendar-list__month-separator-text {
  padding: 0 0 0 16px !important;
}

.single-widgt-event-events-list__event-row {
  border-bottom: 1px solid $inputBorderColor !important;
}

.single-events-calendar-list__event-date-tag-weekday,
.single-widget-event-date-tag-month {
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5;
}

.single-widget-event-date-tag-daynum,
.single-events-calendar-list__event-date-tag-daynum {
  line-height: 1;
  font-weight: 700;
}

@media screen and (min-width: $breakpoint-small) {
  .archive-tickets-content-events {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

@media screen and (min-width: $breakpoint-small) {
  .content-header-block {
    padding: 2.4rem;
  }

  .single-tickets {
    .head-title {
      padding: 0 0 0 0;
    }

    .content-header-details {
      text-align: left;
    }
  }

  .single-tribe_events {
    .tribe-events-event-image {
      display: block;
    }

    .dvTpXv {
      display: inline-block;
    }

    .tickets__hall {
      display: inline-block;
    }

    .tickets__schedule {
      display: inline-block;
    }
  }

  .tickets__header {
    grid-template-columns: minmax(24px, 1fr) minmax(auto, calc(906px)) 48px 294px minmax(24px, 1fr);
    grid-template-rows: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .archive-tickets-content-events {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

@media screen and (min-width: $breakpoint-large) {
  .content-header-block {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .archive-tickets-content-events {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .single-tickets-content {
    flex-direction: row;
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#close {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 200;
  border: 0;
  outline: 0;
  color: transparent;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

.fTybrq {
  margin: 10px;
  transform: rotate(90deg);
  fill: $white;
}

.event-panel-header {
  position: relative;
  text-align: center;
  min-height: 50px;
  border-bottom: 1px solid rgb(191, 191, 191);
}

.event-header-actions {
  padding-top: 6px;

  .button {
    &:not(:disabled) {
      &:hover,
      &:focus {
        outline: currentColor auto 2px;
      }
    }
  }
}

.event-panel-description {
  padding: 16px;
}

.czTaBT {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;

  &.is-shown {
    display: block;
  }
}

/* stylelint-enable no-descending-specificity */
