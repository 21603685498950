.tribe-events-header__breadcrumbs {
  display: none;
}

.single-venues-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.single-venue__meta-title {
  line-height: 1.5;
}

.single-events-venue__meta-address {
  display: flex;
  flex-direction: row;

  svg {
    display: inline-block;
    height: 16px;
    margin: 3px 6px 0 0;
  }
}

.single-events-venue__meta-phone {
  margin: 4px 0 0 0 !important;

  svg {
    display: inline-block;
    width: 12.45px;
    margin: 6px 1px 0 0;
  }
}

.single-events-venue__meta-phone-number {
  display: inline-block;
  line-height: 1.5;
}

.single-events-venue__meta-address-details {
  display: inline-block;
}

.tribe-address {
  display: block;
  margin: 0 0 8px 0;

  span {
    line-height: 1.5;
  }
}

@media screen and (min-width: $breakpoint-medium) {
  .single-venues-content {
    flex-direction: row;
    padding: 16px !important;
  }

  .single-venue__meta-title {
    margin: 0;
  }
}

@media screen and (min-width: $breakpoint-large) {
  .single-venues-content {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
