.sidebar-mobile {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  background-color: hsla(210deg, 30%, 8%, 0.95);
  transition: opacity 500ms ease 0s;
  will-change: transform;
  pointer-events: none;
  z-index: 3;

  @media (min-width: $breakpoint-large) {
    display: none;
  }

  ul {
    margin-top: 60px;
    margin-right: auto;

    li {
      margin: 0 0 0 0;
      padding: 16px 16px 16px 32px;

      a {
        display: block;
        font-weight: 700;
        color: $white;
        user-select: none;
      }
    }
  }
}

.hamburger-open {
  .sidebar-mobile {
    opacity: 1;
    pointer-events: auto;
  }
}
