// Breakpoints
// ========================================================================
$section-xsmall-padding-vertical:                  16px !default;
$section-small-padding-vertical:                   24px !default;

$breakpoint-small: 640px !default; // Phone landscape
$breakpoint-medium: 960px !default; // Tablet Landscape
$breakpoint-large: 1200px !default; // Desktop
$breakpoint-xlarge: 1600px !default; // Large Screens

$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;

$notification-message-font-size: 16px;
$border-rounded-border-radius:                   4px !default;
$button-text-transform: inherit !default;

//------------------------------------*\
//  # Layout
//------------------------------------*/
$minWidth: false; // min container width, false to disable
$maxWidth: false; // max container width, false to disable

$bumperPadding: 6% !default; // bumper padding on containers, false to disable
$padContainer: true !default; // enable bumperPadding on containers

$gridMargin: 5% !default; // default margin for spaced columns
$gridColumns: 8 !default; // default number of columns in grid

$gridSpaceless: false !default; // set to true to eliminate inline grid whitespace hack

$blockMarginBottom: 4rem !default; // default spacing for lists, form elements, and other blocks

//------------------------------------*\
//  # Colors
//------------------------------------*/

$primary: #009cde; //#0091ea
$secondary: rgb(0, 63, 160);
$tertiary: rgb(19, 16, 33);
$highlight: hsl(333deg, 100%, 52%);
$info: #1a28ff !default;
$success: #078a09 !default;
$warning: #a41818 !default;

$main-color: #0057ff;
$input-background: rgb(32, 27, 26);
$grey-low: #ddd;
$transparent: rgba(0, 0, 0, 0);
$shadow-1: rgba(255, 255, 255, 0.1);

$global-primary-background: $primary;
$global-secondary-background: $secondary;

//------------------------------------*\
//  # Grayscale
//------------------------------------*/

$white: rgba(255, 255, 255, 1) !default;
$lightestGray: darken(#fff, 4%) !default;
$lighterGray: darken(#fff, 10%) !default;
$lightGray: darken(#fff, 25%) !default;
$gray: darken(#fff, 35%) !default;
$darkGray: darken(#fff, 55%) !default;
$darkerGray: darken(#fff, 65%) !default;
$darkestGray: darken(#fff, 75%) !default;
$black: #000 !default;

//------------------------------------*\
//  # Links
//------------------------------------*/

$linkColor: $primary !default;
$linkColorHover: darken($linkColor, 10%) !default;
$linkColorActive: darken($linkColor, 20%) !default;

$linkDecoration: none !default; // none, underline
$linkDecorationHover: false !default; // none, underline, false

// All rem-based units are relative to the root size above

$baseColor: rgba(0, 0, 0, 0.8) !default;
//$baseFont: arial, helvetica, sans-serif !default;
$baseFont: museo-sans, sans-serif !default;
$baseFontWeight: normal !default;
$baseLineHeight: 1em !default;

$bodyBackground: #0e0b26 !default; // false to disable
//$bodyBackground: #001834/#002866;

//------------------------------------*\
//  # Miscellaneous
//------------------------------------*/

$selectionColor: $white !default;
$selectionBackground: $linkColor !default;

$boldFontWeight: bold !default;
$normalFontWeight: normal !default;

$markColor: $baseColor !default;
$markBackground: yellow !default;

$defaultRadius: 3px !default;
$defaultOpacity: 0.2 !default;
$defaultDuration: 0.2s !default;
$defaultTiming: ease-in-out !default;

$defaultBorderColor: rgba(255, 255, 255, 0.1);
$defaultBorderWidth: 1px !default;

$abbrUnderline: dotted !default; // dotted, solid, none

//------------------------------------*\
// # Fonts
//------------------------------------*/

$fontPath: "../fonts/" !default; // absolute or relative path

$iconFont: icons !default;

$woff2Enabled: true !default; // false to disable

//------------------------------------*\
//  # Paragraphs
//------------------------------------*/

$paragraphColor: $baseColor !default;
$paragraphFontWeight: $baseFontWeight !default;
$paragraphLineHeight: 1.5 !default;
$paragraphMargin: 0 !default;
$paragraphMarginBottom: 1.6rem !default;

//------------------------------------*\
//  # Fonts
//------------------------------------*/

$fontPath: "../fonts/" !default; // absolute or relative path

$iconFont: icons !default;

$woff2Enabled: true !default; // false to disable

//------------------------------------*\
//  # Forms
//------------------------------------*/

// Inputs

$inputColor: $white !default;
$inputFont: $baseFont !default;
$inputFontWeight: $baseFontWeight !default;
$inputBackground: lighten($bodyBackground, 5%) !default;
$inputBorderColor: $shadow-1 !default;
// $inputBorderColorHover: darken($inputBorderColor, 10%) !default;
// $inputBorderColorFocus: darken($inputBorderColor, 20%) !default;
$inputBorderColorHover: $primary !default;
$inputBorderColorFocus: $primary !default;
$inputBorderWidth: 2px !default; // false to disable
$inputRounded: $defaultRadius !default; // false to disable
$inputMinWidth: 20rem !default; // false to disable
$inputMinHeight: 4.4rem !default; // false to disable
$inputPaddingHorizontal: 0.7rem !default;
$inputPaddingVertical: 0.3rem !default;
$inputMarginBottom: 2rem !default;
$inputPlaceholderColor: lighten($inputColor, 40%) !default;

// Invalid Inputs

$inputBackgroundInvalid: lighten($bodyBackground, 5%) !default;
$inputBorderColorInvalid: $warning !default;
$inputColorInvalid: darken($inputBorderColorInvalid, 10%) !default;
$inputBorderColorInvalidHover: darken($inputBorderColorInvalid, 10%) !default;
$inputBorderColorInvalidFocus: darken($inputBorderColorInvalid, 20%) !default;
$inputBorderWidthInvalid: 2px !default; // false to disable

// Required Inputs

$inputBackgroundRequired: lighten($bodyBackground, 5%) !default;
$inputBorderColorRequired: $darkGray !default;
$inputColorRequired: darken($inputBorderColorRequired, 10%) !default;
$inputBorderColorRequiredHover: darken($inputBorderColorRequired, 10%) !default;
$inputBorderColorRequiredFocus: darken($inputBorderColorRequired, 20%) !default;
$inputBorderWidthRequired: 2px !default; // false to disable

// Disabled Inputs

$inputColorDisabled: $darkGray !default;
$inputBackgroundDisabled: $lightestGray !default;

$inputBorderWidthDisabled: 2px !default; // false to disable

$inputCursorDisabled: not-allowed !default;

$inputDisabledModifier: -disabled !default;
$inputDisabledCursor: not-allowed !default;

// Selects

$multiSelectMinHeight: 8rem !default;

// Checkboxes

$checkboxMarginTop: 0.1rem !default;
$checkboxMarginRight: 0.7rem !default;

// Textareas

$textareaLineHeight: 1.3em !default;
$textareaMinHeight: 8rem !default;
$textareaResize: vertical !default; // none, horizontal, vertical, both
$textareaPaddingHorizontal: 1.6rem !default;
$textareaPaddingVertical: 1rem !default;

// Legends

$legendColor: $darkerGray !default;
$legendFont: $baseFont !default;
$legendFontSize: 1.8rem !default;

$legendMarginBottom: 1.4rem !default;

// Labels

$labelFontWeight: normal !default;
$labelLineHeight: 1.3em !default;

$labelMarginBottom: 0.4rem !default;
$labelMarginRight: 1rem !default;

//------------------------------------*\
//  # Buttons
//------------------------------------*/

$buttonStyled: true !default;

$buttonColor: $white !default;
$buttonFont: $baseFont !default;
$buttonFontWeight: normal !default;

$buttonPaddingHorizontal: 3rem !default;
$buttonPaddingVertical: 0 !default;

$buttonMarginBottom: false !default;

$buttonRounded: $defaultRadius !default; // false to disable

$buttonTransitionEnabled: true !default;
$buttonTransitionProperty: color, background-color, border-color !default;
$buttonTransitionDuration: $defaultDuration !default;

$buttonActiveTransitionEnabled: false !default;
$buttonActiveTransitionProperty: color, background-color, border-color !default;
$buttonActiveTransitionDuration: $defaultDuration !default;

$buttonBackground: $transparent !default;
$buttonBackgroundHover: darken($buttonBackground, 5%) !default;
$buttonBackgroundActive: darken($buttonBackground, 10%) !default;

$buttonBorderColor: $white !default; // false to disable
$buttonBorderColorHover: $primary;
$buttonBorderColorActive: $primary;
// Colored Buttons

$coloredButtonClassModifier: button-colored !default;

$coloredButtonColor: $white !default; // font color

$coloredButtonBackground: $linkColor !default;
$coloredButtonBackgroundHover: darken($coloredButtonBackground, 5%) !default;
$coloredButtonBackgroundActive: darken($coloredButtonBackground, 10%) !default;

$coloredButtonBorderColor: false !default;

// Disabled Buttons

$disabledButtonClassModifier: -disabled !default;

$disabledButtonColor: $darkGray !default;

$disabledButtonBackground: darken($secondary, 10%) !default;

$disabledButtonBorderColor: false !default;

$disabledButtonCursor: not-allowed !default;

//------------------------------------*\
//  # Lists
//------------------------------------*/

$listColor: $paragraphColor !default;
$listLineHeight: $paragraphLineHeight !default;

$listMarginBottom: $paragraphMarginBottom !default;
$listMarginLeft: false !default; // false to disable

$listBulletStyle: disc !default; // disc, circle, square, etc
$listBulletPosition: inside !default; // inside or outside

$nestedListMarginLeft: 2rem !default;

$liMarginBottom: 0.2rem !default; // false to disable

// Description Lists

$dlMarginBottom: $blockMarginBottom !default;

// Description List Titles

$dtColor: $darkerGray !default;
$dtFontSize: 2rem !default;

$dtMarginBottom: 0.2rem !default;

// Description List Items

$ddColor: $gray !default;
$ddFont: $baseFont !default;

$ddMarginBottom: 1rem !default;

/** Box Model  */
$spacer: 2rem;

/** Width  */
$blog-width: 1032px;
$single-width: 680px;
